/**
 * This file is used to import required libraries and place them on the window object for usage across all of Synergy's pages.
 *
 * If new global libraries are required, they should be registered here and attached to the window object.
 */

// CKEditor4 loads in external dependencies lazily via AJAX and including them into the page
// unfortunately since we're not bundling them in the output, we have to load them off of their CDN instead
// This just tells CKEditor4 to use this as the "base url" for its includes when fetching files.
// This has to be done somewhere in the "app.js" file and not in ckeditor, as the vendor splitting places this after
// the vendor import from ckeditor4.
window.CKEDITOR_BASEPATH = '/static/ckeditor4/';

import * as Sentry from '@sentry/browser';

window.Sentry = Sentry

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_ALERT_DSN,
    tunnel: "/sentry",
});

import jquery from 'jquery';
import 'jquery-migrate';

import 'jquery-ui/dist/jquery-ui';
import './old/jquery-ui-timepicker-addon';

import '@fancyapps/fancybox';

window.jQuery = window.$ = jquery;

jQuery.migrateMute = import.meta.env.PROD

import * as luxon from 'luxon';

window.luxon = luxon;

import 'chosen-js';

import toastr from 'toastr';

window.toastr = toastr;

import swal from 'sweetalert2/dist/sweetalert2';

window.swal = window.sweetAlert = window.Swal = window.SweetAlert = window.Sweetalert2 = swal;

import 'htmx.org'
