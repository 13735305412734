// BETTER ERROR AVOIDANCE & VALIDATION ENHANCEMENT RESOURCE - a.k.a. BEAVER
/*
                   |    :|
                   |     |
                   |    .|
               ____|    .|
             .' .  ).   ,'
           .' c   '7 ) (
       _.-"       |.'   `.
     .'           "8E   :|
     |          _}""    :|
     |         (   |     |
    .'         )   |    :|
.odCG8o_.---.__8E  |    .|
`Y8MMP""       ""  `-...-'

data-validate: Input validation rules separated by the '|' pipeline symbol
Note: some validation rules support additional parameters, e.g. rule:param,value

Available Rules:
    required                            value cannot be blank or empty
    required_if:anotherfield,value      required if another field is set to a specified value
    required_when:anotherfield          required if another field has any value
    email                               must be a valid email address in the format a@b.c
    email_prefix:example.com            must be a valid email address (given the domain name) in the format value@example.com
    phone                               must be a valid phone number in the format +61400000000
    alpha                               must only contain alphabetic characters (e.g. A-Z, a-z)
    alnum                               must only contain alphanumeric characters (e.g. A-Z, a-z, 0-9)
    number                              must only contain numbers (e.g. 0-9)
    confirmed                           similar to same, except a field name does not need to be set - a field id such as originalId_confirmed is expected
    same:anotherfield                   must match another fields value
    min:value                           must be greater than (or equal to) the specified minimum value
    max:value                           must be less than (or equal to) the specified maximum value
    between:min,max                     must be within the specified range
    contains_alpha:min                  must contain the specified minimum number of alphabetic characters (min 1 by default)
    contains_number:min                 must contain the specified minimum number of numeric characters (min 1 by default)
    contains_special:min                must contain the specified minimum number of special characters (min 1 by default)
    starts_with:alpha|alnum|value       must start with an alphabetic character, alphanumeric character or other value respectively
    regex:expression,flags              must match a specified expression, note: expression must not contain boundaries '/' or flags '/g/i/etc.'
                                        additionally, if the expression contains '|' or ',' characters it may be necessary to specify rules in an array instead
 */

// Define field selectors
const VALIDATE_CONDITIONAL_BTN_SELECTOR = 'data-validate-conditional';
const VALIDATE_FIELD_SELECTOR = 'data-validate';
const VALIDATE_CUSTOM_ERROR_CALLBACK = 'data-validate-error-callback';
const VALIDATE_FORCE_UPDATE = 'data-validate-force-update';
const VALIDATE_FORCE_UPDATE_ON_LOAD = 'data-validate-force-update-on-load';
const VALIDATE_FORCE_UPDATE_ON_LOAD_FOR_ELEMENT = 'data-validate-force-update-on-load-for-element';

// Define class names
const VALIDATE_ERROR_CLASS = 'validation-error';
const VALIDATE_ERROR_CLASS_ENTERING = 'entering';
const VALIDATE_ERROR_CLASS_LEAVING = 'leaving';
const VALIDATE_SUCCESS_CLASS = 'validation-success';
const VALIDATE_ERROR_FIELD_CLASS_PREFIX = 'error';

// Define rule names
const RULE_REQUIRED = 'required';
const RULE_REQUIRED_IF = 'required_if';
const RULE_REQUIRED_WITH = 'required_with';
const RULE_EMAIL = 'email';
const RULE_EMAIL_PREFIX = 'email_prefix';
const RULE_PHONE = 'phone';
const RULE_ALPHA = 'alpha';
const RULE_ALNUM = 'alnum';
const RULE_NUMERIC = 'numeric';
const RULE_CONFIRMED = 'confirmed';
const RULE_SAME = 'same';
const RULE_MIN = 'min';
const RULE_MAX = 'max';
const RULE_BETWEEN = 'between';
const RULE_CONTAINS_ALPHA = 'contains_alpha';
const RULE_CONTAINS_NUMBER = 'contains_number';
const RULE_CONTAINS_SPECIAL = 'contains_special';
const RULE_STARTS_WITH = 'starts_with';
const RULE_REGEX = 'regex';

// Define validation errors
const VALIDATION_ERROR_MESSAGES = {
    [RULE_REQUIRED]: 'The {fieldName} field is required and cannot be empty.',
    [RULE_REQUIRED_IF]: 'The {fieldName} field is required and cannot be empty.',
    [RULE_REQUIRED_WITH]: 'The {fieldName} field is required and cannot be empty.',
    [RULE_EMAIL]: 'The {fieldName} field must be a valid email address.',
    [RULE_EMAIL_PREFIX]: 'The {fieldName} field must be a valid email address.',
    [RULE_PHONE]: 'The {fieldName} field must be a valid phone number. (+61400000000)',
    [RULE_ALPHA]: 'The {fieldName} field must contain only alphabetic characters.',
    [RULE_ALNUM]: 'The {fieldName} field must only contain alphanumeric characters (A-Z, 0-9).',
    [RULE_NUMERIC]: 'The {fieldName} field must only contain numeric characters (0-9).',
    [RULE_SAME]: 'The {fieldName} fields must match.',
    [RULE_CONFIRMED]: 'The {fieldName} fields must match.',
    [RULE_MIN]: 'The {fieldName} field must be greater than {param1} in length.',
    [RULE_MAX]: 'The {fieldName} field must be less than {param1} in length.',
    [RULE_BETWEEN]: 'The {fieldName} field must be between {param1} and {param2}.',
    [RULE_CONTAINS_ALPHA]: 'The {fieldName} field must contain at least {param1} alphabetic character(s).',
    [RULE_CONTAINS_NUMBER]: 'The {fieldName} field must contain at least {param1} number(s).',
    [RULE_CONTAINS_SPECIAL]: 'The {fieldName} field must contain at least {param1} special character(s).',
    [RULE_STARTS_WITH]: 'The {fieldName} field must start with an {param2} character.',
    [RULE_REGEX]: 'The {fieldName} must be valid.'
}


// Support Functions

/**
 *  Validate fields provided in the event handler.
 *  Note: for fields using the 'same' validation rule, validation will also be re-applied to the related field.
 *
 *  @param {Event} event Event handler
 */
function validateInstant(event) {
    let fieldId = event.target.id;
    let result = [];

    // Check if the current field is related to another using the same validator, re-run validation if needed
    let relatedField = $(`[${VALIDATE_FIELD_SELECTOR}*='${RULE_SAME}:${fieldId}']`)[0];
    if (relatedField) {
        result = validateField(relatedField.id);
        if (result.length >= 1) {
            addValidationError(relatedField.id, result);
        } else {
            clearValidationErrors(fieldId);
            addValidationSuccess(relatedField.id);
        }
    }

    // Check validation results
    result = result.concat(validateField(fieldId));
    if (result.length >= 1) {
        addValidationError(fieldId, result);
    } else {
        clearValidationErrors(fieldId);
        addValidationSuccess(fieldId);
    }

    // Check if the form is valid
    checkFormValidity();
}

/**
 *  Clear validation errors for a specific field if set, otherwise clear all validation errors.
 *  Note: if a custom error callback is defined, the callback will be supplied an empty array to indicate errors have been cleared.
 *
 *  @param {String} fieldId Input field id
 */
function clearValidationErrors(fieldId = null) {
    if (fieldId) {
        let field = $(`#${fieldId}`);
        if (field.attr(VALIDATE_CUSTOM_ERROR_CALLBACK)) {
            try {
                window[field.attr(VALIDATE_CUSTOM_ERROR_CALLBACK)](fieldId, []);
            } catch (err) {
                console.log(err);
            }
        }

        field.removeClass([VALIDATE_ERROR_CLASS, VALIDATE_SUCCESS_CLASS]);
        $(`.${VALIDATE_ERROR_CLASS}.${VALIDATE_ERROR_FIELD_CLASS_PREFIX}-${fieldId}`).remove();
    } else {
        $(`${VALIDATE_FIELD_SELECTOR}`).removeClass([VALIDATE_ERROR_CLASS, VALIDATE_SUCCESS_CLASS]);
    }
}

/**
 *  Add validation error messages to the corresponding field.
 *  Note: if a custom error callback is defined, then any validation errors will be passed onto the callback rather than displayed as standard.
 *
 *  @param {String} fieldId Input field id
 *  @param {Array} errorMessages Validation errors
 */
function addValidationError(fieldId, errorMessages) {
    let field = $(`#${fieldId}`);

    if (field.data("chosen") !== undefined) {
        field = $(`#${fieldId}_chosen`);
    }

    field.removeClass(VALIDATE_SUCCESS_CLASS);
    field.addClass(VALIDATE_ERROR_CLASS);

    let customCallback = field.attr(VALIDATE_CUSTOM_ERROR_CALLBACK);
    if (customCallback) {
        try {
            if (typeof window[customCallback] === 'function') {
                window[customCallback](fieldId, errorMessages);
            } else {
                console.error(`Function ${customCallback} is not defined or not a function on window`);
            }
        } catch (err) {
            console.error(`Error calling custom callback ${customCallback}:`, err);
        }
    } else {
        let required = errorMessages.find(e => [RULE_REQUIRED, RULE_REQUIRED_IF, RULE_REQUIRED_WITH].includes(e.type));

        if (required) {
            handleErrors(field, fieldId, [required]);
        } else {
            handleErrors(field, fieldId, errorMessages);
        }
    }
}

function errorExists(fieldId, message) {
    return $(`.${VALIDATE_ERROR_FIELD_CLASS_PREFIX}-${fieldId}`).filter(function() {
        return $(this).text() === message;
    }).length > 0;
}

function handleErrors(field, fieldId, errorMessages) {
    let currentErrors = [];

    errorMessages.forEach(error => {
        currentErrors.push(error.message);

        if (!errorExists(fieldId, error.message)) {
            let errorElement = $(`<div class='${VALIDATE_ERROR_CLASS} ${VALIDATE_ERROR_CLASS_ENTERING} ${VALIDATE_ERROR_FIELD_CLASS_PREFIX}-${fieldId}'>${error.message}</div>`)
            field.after(errorElement);

            setTimeout(function() {
                errorElement.removeClass(VALIDATE_ERROR_CLASS_ENTERING);
            }, 200);
        }
    });

    $(`.${VALIDATE_ERROR_FIELD_CLASS_PREFIX}-${fieldId}`).each(function() {
        let errorElement = $(this);
        if (!currentErrors.includes(errorElement.text())) {
            errorElement.addClass(VALIDATE_ERROR_CLASS_LEAVING);

            setTimeout(function() {
                errorElement.remove();
            }, 200);
        }
    });
}

/**
 *  Add a validation success message to the corresponding field.
 *
 *  @param {String} fieldId Input field id
 *  @param {String} successMessage Success message
 */
function addValidationSuccess(fieldId, successMessage = null) {
    let field = $(`#${fieldId}`);

    if (field.data("chosen") !== undefined) {
        field = $(`#${fieldId}_chosen`);
    }

    field.addClass(VALIDATE_SUCCESS_CLASS);

    if (successMessage) {
        // TODO: add support here for custom field success messages
    }
}

/**
 *  Silently validates every validate field and returns validity status, also enables/disables the conditional button if defined.
 *
 *  @returns {Boolean} Form validity
 */
function checkFormValidity() {
    let errors = [];

    $('[data-validate]').each((index, element) => {
        let result = validateField(element.id);
        if (result.length >= 1) {
            errors.push(result);
        }
    });

    $(`[${VALIDATE_CONDITIONAL_BTN_SELECTOR}]`).attr('disabled', errors.length > 0);

    return errors.length === 0;
}

function checkFormValidityOnLoad() {
    let errors = [];

    $('[data-validate]').each((index, element) => {
        const fieldId = element.id

        // Check if the current field is related to another using the same validator, re-run validation if needed
        let relatedField = $(`[${VALIDATE_FIELD_SELECTOR}*='${RULE_SAME}:${fieldId}']`)[0];
        if (relatedField) {
            let result = validateField(relatedField.id);
            if (result.length >= 1) {
                addValidationError(relatedField.id, result);
                errors.push(result);
            } else {
                // Reset form errors and conditional button if needed
                clearValidationErrors(fieldId);
                addValidationSuccess(relatedField.id);
            }
        }

        // Check validation results
        let result = validateField(fieldId);
        if (result.length >= 1) {
            addValidationError(fieldId, result);
            errors.push(result);
        } else {
            // Reset form errors and conditional button if needed
            clearValidationErrors(fieldId);
            addValidationSuccess(fieldId);
        }
    });

    $(`[${VALIDATE_CONDITIONAL_BTN_SELECTOR}]`).attr('disabled', errors.length > 0);

    return errors.length === 0;
}

/**
 *  Validates a given field using the validation rules supplied in the data-validate attribute.
 *
 *  @param {String} id Input field id
 *  @returns {Array} Validation error objects
 */
function validateField(id) {
    let field = $(`#${id}`);
    let fieldName = field.attr('title') ?? field.attr('id');
    let rules = field.attr(VALIDATE_FIELD_SELECTOR).split('|');
    let errors = [];

    rules.forEach(function (rule) {
        let error = false;
        let param1;
        let param2;
        let type;
        let matches;

        // Parse rule type and additional parameters
        if (rule.includes(':')) {
            let params = rule.split(':');
            type = params.shift();
            if (params[0].includes(',')) {
                [param1, param2] = params[0].split(',');
            } else {
                param1 = params[0] ?? null;
            }
        } else {
            type = rule;
        }

        // If it's not required, and it's empty return early
        if ((!field.val() || field.val().trim() === '') && !rules.includes('required')) {
            return
        }

        // Type-specific validation
        switch (type) {
            case RULE_REQUIRED:
                error = !field.val() || field.val().trim() === '' || (field.is(':checkbox') && !field.is(':checked'));
                break;
            case RULE_REQUIRED_IF:
                if (param1 && param2) {
                    error = field.val() && $(`#${param1}`).val() !== param2;
                }
                break;
            case RULE_REQUIRED_WITH:
                if (param1) {
                    let value = $(`#${param1}`);
                    if (value.attr('type') === 'checkbox') {
                        value = value.is(":checked");
                    } else {
                        value = value.val();
                    }
                    error = (!field.val() || field.val().trim() === '') && value;
                }
                break;
            case RULE_EMAIL:
                // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#validation
                error = field.val() && !isValidEmail(field.val());
                break;
            case RULE_EMAIL_PREFIX:
                param1 = param1 ?? 'example.com';
                error = field.val() && !isValidEmail(field.val() + "@" + param1);
                break;
            case RULE_PHONE:
                error = field.val().match(/^(?:\+?(61|64))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/) === null;
                break;
            case RULE_ALPHA:
                error = field.val().match(/^[A-Za-z ]+$/) === null;
                break;
            case RULE_ALNUM:
                error = field.val().match(/^[A-Za-z0-9 ]+$/) === null;
                break;
            case RULE_NUMERIC:
                error = field.val().match(/^[0-9]+$/) === null;
                break;
            case RULE_CONFIRMED:
                let confirmed = $(`#${field.attr('id')}_confirmed`);
                if (confirmed) {
                    error = field.val() !== confirmed.val();
                }
                break;
            case RULE_SAME:
                if (param1) {
                    error = field.val() !== $(`#${param1}`).val();
                }
                break;
            case RULE_MIN:
                if (param1) {
                    if (field.attr('type') === 'text' || field.attr('type') === 'password') {
                        error = field.val().length < param1;
                    } else {
                        error = field.val() < param1;
                    }
                }
                break;
            case RULE_MAX:
                if (param1) {
                    if (field.attr('type') === 'text' || field.attr('type') === 'password') {
                        error = field.val().length > param1;
                    } else {
                        error = field.val() > param1;
                    }
                }
                break;
            case RULE_BETWEEN:
                if (param1 !== null && param2 !== null) {
                    if (typeof field.val() === 'string' || field.attr('type') === 'password') {
                        error = field.val().length < param1 || field.val().length > param2;
                    } else {
                        error = field.val() < param1 || field.val() > param2;
                    }
                }
                break;
            case RULE_CONTAINS_ALPHA:
                param1 = param1 ?? 1;
                matches = field.val().match(/^[A-Za-z ]+$/g) ?? [];
                error = matches.length < param1;
                break;
            case RULE_CONTAINS_NUMBER:
                param1 = param1 ?? 1;
                matches = field.val().match(/\d/g) ?? [];
                error = matches.length < param1;
                break;
            case RULE_CONTAINS_SPECIAL:
                param1 = param1 ?? 1;
                matches = field.val().match(/[!@#$%^&*<>?:;+_=\-"'|[\]{}()]/g) ?? [];
                error = matches.length < param1;
                break;
            case RULE_STARTS_WITH:
                if (param1) {
                    switch (param1) {
                        case RULE_ALPHA:
                            param1 = /^[A-Za-z]/;
                            param2 = 'alphabetic';
                            break;
                        case RULE_ALNUM:
                            param1 = /^\d/;
                            param2 = 'alphanumeric';
                            break;
                        case RULE_NUMERIC:
                            param1 = /^[0-9]/;
                            param2 = 'alphanumeric';
                            break;
                        default:
                            param2 = param1;
                            param1 = `/^[${param1}]/`;
                            break;
                    }
                    error = field.val().match(param1) === null;
                }
                break;
            case RULE_REGEX:
                if (param1) {
                    // TODO: fix bug with certain characters i.e. '|' and ',' that will break rule parser
                    error = field.val().match(new RegExp(param1, param2 ?? 'g')) === null;
                }
                break;
            default:
                break;
        }

        if (error) {
            errors.push({
                type: type,
                message: VALIDATION_ERROR_MESSAGES[type].replace('{fieldName}', fieldName.toLowerCase()).replace('{param1}', param1).replace('{param2}', param2)
            });
        }
    });

    return errors;
}

/**
 *  Checks if a given email address is valid, according to RFC standards - not this is not an exhaustive check
 *
 *  @param {String} address Email address
 *  @returns {Boolean} Indicates whether email is valid or not
 */
function isValidEmail(address) {
    // Check the basic format
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#validation
    if (address.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/) === null) {
        return false;
    }

    // Separate out local and domain parts
    let [local, domain] = address.split('@');

    // Check if local and domain parts are within character limits
    if (local.length > 64 || domain.length > 255) {
        return false;
    }

    // Check if local part ends or starts with a '.'
    return !(local.startsWith('.') || local.endsWith('.'));
}


// Load BEAVER
$(`[${VALIDATE_FORCE_UPDATE}]`).on('change', () => checkFormValidity());
$(`[${VALIDATE_FIELD_SELECTOR}]`).on('keyup change', e => validateInstant(e));

if ($(`[${VALIDATE_FORCE_UPDATE_ON_LOAD}]`).length > 0) {
    checkFormValidityOnLoad();
}

let validateForceUpdateOnLoadForElement = $(`[${VALIDATE_FORCE_UPDATE_ON_LOAD_FOR_ELEMENT}]`);
// This is for validation an element on load (something like country which we pre-populate)
// This is needed for chosen to load before we do stuff (╯°□°）╯︵ ┻━┻
if (validateForceUpdateOnLoadForElement.length > 0) {
    validateForceUpdateOnLoadForElement.on('keyup change', e => validateInstant(e));
    validateForceUpdateOnLoadForElement.trigger("change");
}

